import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'

export default function StorageShelfLifeTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  const materialsTableHeaders = [
    'Part Description',
    'MSDS KT DOC ID',
    'Material',
  ]
  return (
    <Layout title={documentName} filePath={filePath}>
      <p>
        The materials used in the construction of the TZ Medical {productName}{' '}
        consists of:
      </p>
      <Table
        table={frontmatter.materialsTable}
        headers={materialsTableHeaders}
      />
      <h4 className="title is-4">Results</h4>
      <p>
        It is anticipated that the {productName} will be stored at room
        temperature.
      </p>
      {frontmatter.specialStorageConditionsRequired === 'true' ? (
        <div>
          <p>
            Using Arrhenius Theory during the Accelerated Aging section of the
            Packaging Validation (Doc ID XXXXX), we have determined a shelf life
            of {frontmatter.shelfLife} years. Transportation limits of{' '}
            {frontmatter.transportationTemperatureLowerLimit} °C—
            {frontmatter.transportationTemperatureUpperLimit} °C shall be
            listed.
          </p>
          <h4 className="title is-4">Determination</h4>
          <p>The following conditions are indicated for this product:</p>
          <table
            style={{
              width: '80%',
              marginLeft: '0px',
              marginRight: 'auto',
              textAlign: 'center',
            }}
          >
            <tr>
              <th>Temperature</th>
              <th>Humidity</th>
              <th>Shelf Life</th>
            </tr>
            <tr>
              <td>
                {frontmatter.transportationTemperatureLowerLimit} °C—
                {frontmatter.transportationTemperatureUpperLimit} °C
              </td>
              <td>Non-condensing</td>
              <td>{frontmatter.shelfLife} Years</td>
            </tr>
          </table>
        </div>
      ) : (
        <p>
          The materials used in the construction of {productName} do not require
          special storage conditions as specified by the manufacturer.
        </p>
      )}
      <p>Signature: </p>
      <p>Printed Name: </p>
      <p>Printed Title: </p>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryStorageShelfLife($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        materialsTable {
          partDescription
          MSDSKTDOCID
          material
        }
        specialStorageConditionsRequired
        shelfLife
        transportationTemperatureLowerLimit
        transportationTemperatureUpperLimit
      }
    }
  }
`
